import React from 'react';
import Link from "gatsby-link";

const Banner = () => {
    return (
        <div className="relative bg-primary">
            <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                <div className="pr-16 sm:text-center sm:px-16">
                    <p className="font-medium text-white">
                        <span className="inline">Een wedstrijdcode ontvangen?</span>
                        <span className="block sm:ml-2 sm:inline-block">
                          <Link to="/actie" className="text-white font-bold underline">
                              Ontdek hier<span aria-hidden="true"></span>
                          </Link>
                            <span> wat je gewonnen hebt!</span>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Banner;
