import React, {useEffect, useState} from 'react'
import Footer from "./components/Footer";
import Banner from "./components/Banner";
import Header from "./components/Header";
import {graphql} from "gatsby";
import {map, filter, orderBy} from 'lodash';
import {Helmet} from "react-helmet";
import Link from "gatsby-link";
import {useQuery} from "../hooks/useQuery";

export const HOME_PICS_QUERY = `
query HomePictures {
  homePictures {
    url
}
}`;


export default function Home({data}) {
    const {data: dataFromFetch, fetchData, loading, error} = useQuery()
    // const [images, setImages] = useState(data && data.cms.homePictures ? data.cms.homePictures : [])
    const [images, setImages] = useState([])
    const makes = data && data.cms.makes ? orderBy(filter(data.cms.makes, (m) => m.priority > 0 && m.priority <= 6), ['priority']) : [];

    useEffect(() => {
        fetchData(HOME_PICS_QUERY)
    }, []);


    useEffect(() => {
        if (dataFromFetch && dataFromFetch.homePictures.length > 0) {
            setImages(dataFromFetch.homePictures)
        }
    }, [dataFromFetch]);
    return (
        <div className="bg-white">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Fashion Antero | Home</title>
            </Helmet>

            <Banner/>

            <Header/>

            {/* Hero section */}
            <div className="relative overflow-hidden">
                <div className="pt-16 pb-80 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48">
                    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
                        <div className="sm:max-w-lg">
                            <h1 className="text-4xl font font-extrabold tracking-tight text-gray-900 sm:text-6xl">
                                <span className="text-primary">Antero</span> kleding voor <span className="text-primary">elke man</span>
                            </h1>
                            <p className="mt-4 text-xl text-gray-500">
                                Het Antero team streeft naar de ultieme winkelbeleving waar de klant centraal staat.
                                Wij staan garant voor een persoonlijke service in een ongedwongen sfeer.
                            </p>
                        </div>
                        <div>
                            <div className="mt-10">
                                {/* Decorative image grid */}
                                <div
                                    aria-hidden="true"
                                    className="pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full"
                                >
                                    {images && images.length > 6 &&
                                    <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                                        <div className="flex items-center space-x-6 lg:space-x-8">
                                            <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                                                <div className="w-44 h-64 rounded-lg overflow-hidden sm:opacity-0 lg:opacity-100">
                                                    <img
                                                        src={images[0].url}
                                                        alt=""
                                                        className="w-full h-full object-center object-cover"
                                                    />
                                                </div>
                                                <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                    <img
                                                        src={images[1].url}
                                                        alt=""
                                                        className="w-full h-full object-center object-cover"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                                                <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                    <img
                                                        src={images[2].url}
                                                        alt=""
                                                        className="w-full h-full object-center object-cover"
                                                    />
                                                </div>
                                                <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                    <img
                                                        src={images[3].url}
                                                        alt=""
                                                        className="w-full h-full object-center object-cover"
                                                    />
                                                </div>
                                                <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                    <img
                                                        src={images[4].url}
                                                        alt=""
                                                        className="w-full h-full object-center object-cover"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                                                <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                    <img
                                                        src={images[5].url}
                                                        alt=""
                                                        className="w-full h-full object-center object-cover"
                                                    />
                                                </div>
                                                <div className="w-44 h-64 rounded-lg overflow-hidden">
                                                    <img
                                                        src={images[6].url}
                                                        alt=""
                                                        className="w-full h-full object-center object-cover"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <main>


                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                        <p className="text-center text-base font-semibold uppercase text-primary tracking-wider text-2xl">
                            Onze populairste merken
                        </p>
                        {makes && makes.length > 0 &&
                        <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
                            {map(makes, (make) => {
                                return (
                                    <Link
                                        to={`/collectie`}
                                        state={{make: make}}
                                        className="col-span-1 flex justify-center py-8 px-8 cursor-pointer">
                                        <img src={make.logoUrl} alt={make.name}/>
                                    </Link>
                                )
                            })}
                        </div>}
                    </div>
                </div>

            </main>
            <Footer/>
        </div>
    )
}

export const query = graphql`
query {
  cms {
    homePictures {   
      url
    }
    makes {  
     id
     name
     logoUrl
     priority
   }
  }
}
`
